import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class CobrosService {

  public editable = false;

  public CobroForm = this.fb.group({
    id: [],
    correlativo: [,[Validators.required]],
    user_id: [ ,[Validators.required]],
    user_confirm_id: [],
    cliente_id: [null],
    cliente_nombre: [],
    fecha_pago: [ ,[Validators.required]],
    contrato_id: [ ,[Validators.required]],
    metodo_pago: [ ,[Validators.required]],
    no_documento_pago: [],
    total_q: [0 ,[Validators.required]],
    total_usd: [0 ,[Validators.required]],
    mora_q: [0],
    mora_usd: [0],
    no_documento_deposito: [],
    final_total: [,[Validators.required]],
  });

  get headers(){
    return {
      headers: {
        Authorization: this.token
      }
    }
  }
  get token(): string{
    const token = 'Bearer ' + (localStorage.getItem('token') || '');
    return token
  }

  constructor(private http: HttpClient,
              private fb: FormBuilder,) { }

  getLastId(){
    const url = `${base_url}/getCobroLastid`; 
    return this.http.get(url, this.headers )
  }

  crearCobro(){
    return this.http.post( `${ base_url }/cobros/crearCobro`, this.CobroForm.value , this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )
  }

  cargarCobros(){
    const url = `${base_url}/cobros`; 
    return this.http.get(url, this.headers )
  }

  mostrarCobros(usuario_id){
    let data = {
      usuario_id: usuario_id,
    }
    return this.http.post( `${ base_url }/cobros/mostrarCobros`, data, this.headers )
    .pipe(
      tap ( (resp: any) => {
      })
    )  
  }

  confirmarCobro(usuario_id: Number, cobro_id: Number){
    let data = {
      user_confirm_id: usuario_id,
    }
    return this.http.patch( `${ base_url }/cobros/${cobro_id}`, data, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )  
  }

  eliminarCobro(cobro_id: Number){
    let data = {
      cobro_id: cobro_id,
    }
    return this.http.post( `${ base_url }/cobros/eliminarCobro`, data, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )  
  }

  agregarDocumentoDeposito(){
    let data = {
      no_documento_deposito: this.CobroForm.get('no_documento_deposito').value,
    }
    let cobro_id = this.CobroForm.get('id').value;
    return this.http.patch( `${ base_url }/cobros/${cobro_id}`, data, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                ) 
  }

  generarReciboCobro(){
    let data = {
      cobro_id: this.CobroForm.get('id').value,
    }
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': this.token,
      })
    };
    return this.http.post( `${ base_url }/generateRecibo`, data, httpOptions )
  }

  enviarCobroCorreo(){
    let data = {
      cobro_id: this.CobroForm.get('id').value,
    }
    return this.http.post( `${ base_url }/send-email-recibo_pdf`, data, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )  
  }
  
}
